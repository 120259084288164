import './SummaryContact.scss'
import { faLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const SummaryContact = () => {
  return <div className='summary-contact-wrapper'>
    <div className='contact-info-wrapper'>
      <div className='contact-row'>
        <div className={'contact-item contact-item-contact'}>
          <div className={'contact-icon'}>
            <FontAwesomeIcon icon={faClock} color={'#20546c'} size={"2x"}/>
          </div>
          <div className='contact-info-wrapper'>
            <p className='contact-title'>Poniedziałek - Piątek</p>
            <p className='contact-info'>
              <p className='contact-info'>7:00 - 17:00</p>
            </p>
          </div>
        </div>

        <div className={'contact-item'}>
          <div className={'contact-icon'}>
            <FontAwesomeIcon icon={faLocationDot} color={'#20546c'} size={"2x"}/>
          </div>
          <div className='contact-info-wrapper'>
            <p className='contact-title'>Adres:</p>
            <p className='contact-info'>
              <span>Czarnochowice 540</span><br/>
              <span>32-020 Wieliczka</span>
            </p>
          </div>
        </div>
      </div>

      <div className='contact-row'>
        <div className={'contact-item'}>
          <div className={'contact-icon'}>
            <FontAwesomeIcon icon={faPhone} color={'#20546c'} size={"2x"}/>
          </div>
          <div className='contact-info-wrapper'>
            <p className='contact-title'>Telefon:</p>
            <p className='contact-info'>
              <p className='contact-info'>+48 721 718 501</p>
            </p>
          </div>
        </div>

        <div className={'contact-item'}>
          <div className={'contact-icon'}>
            <FontAwesomeIcon icon={faEnvelope} color={'#20546c'} size={"2x"}/>
          </div>
          <div className='contact-info-wrapper'>
            <p className='contact-title'>E-mail:</p>
            <p className='contact-info'>
              <a  href="mailto:bajkowyglobus@gmail.com" className='contact-info'>bajkowyglobus@gmail.com</a>
            </p>
          </div>
        </div>

        <div className={'contact-item'}>
          <div className={'contact-icon'}>
            <FontAwesomeIcon icon={faFacebook} color={'#20546c'} size={"2x"}/>
          </div>
          <div className='contact-info-wrapper'>
            <p className='contact-title'>Facebook:</p>
            <p className='contact-info'>
              <a href='https://www.facebook.com/profile.php?id=100088530457088' target='_blank'  rel='noreferrer' className='contact-info'>Link</a>
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
}
