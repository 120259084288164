import './Offer.scss'
import {TitleCaption} from "../../components/TitleCaption/TitleCaption";
import {Widget} from "../../components/Widget/Widget";
import {SectionDivider} from "../../components/SectionDivider/SectionDivider";
import { faPlane, faUserGraduate, faPalette, faHandSparkles, faPaw, faMusic, faCalculator, faUtensils, faHouse } from "@fortawesome/free-solid-svg-icons"
import { faFutbol, faComments } from "@fortawesome/free-regular-svg-icons"
import {faPagelines} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import japan from "../../assets/japonka.png";
import tree from "../../assets/tree.png";
import indian from "../../assets/indianin.png";

export const Offer = () => {
  return <div className='offer-wrapper'>
    <section className='sidebar-image'>
      <img className={'img-japan'} src={japan} alt='Bajkowy globus japonla' />
      <img className={'img-indian'} src={indian} alt='Bajkowy globus indianin'/>
      <img className={'img-tree'} src={tree} alt='Bajkowy globus drzewo'/>
    </section>
    <section className='page-caption'>
      <div className='caption-wrapper'>
        <TitleCaption caption='Oferta'/>
      </div>
    </section>
    <section className='offer-info-children-wrapper'>
      <p className='text'>Zapraszamy wszystkie dzieci od 2,5 do 6 lat!</p>
    </section>
    <section className='offer-widgets'>
      <Widget offerName='Oferta podstawowa' price={750} entryFee={400}/>
      <Widget offerName='Oferta dla rodzeństw' price={650} entryFee={350} priceCustomInfo='miesięcznie za drugie dziecko'/>
    </section>
    {/*<section className='offer-discount'>
      <p className='title'>
        Promocja!
      </p>
      <p className='text'>
        Promocja promocja promocja
      </p>
    </section>*/}
    <SectionDivider />

    <section className='section-included-in-price '>
      <div className='center-container'>
        <TitleCaption caption='Nasza oferta obejmuje'/>
      </div>

      <div className='items-container-row'>
        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faPlane} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Warsztaty podróżnicze</p>
          </div>
        </div>

          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faUserGraduate} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Zajęcia z języka angielskiego 3 razy w tygodniu</p>
            </div>
          </div>


          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faFutbol} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Zajęcia sportowe</p>
            </div>

        </div>

        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faPalette} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Zajęcia plastyczne</p>
          </div>
        </div>


      </div>

      <div className='items-container-row'>
          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faHandSparkles} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Zabawy sensoplastyczne</p>
            </div>
          </div>

          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faPaw} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Dogoterapia</p>
            </div>
        </div>

          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faMusic} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Zajęcia muzyczne</p>
            </div>
          </div>

          <div className='offer-item'>
            <div className='offer-icon'>
              <FontAwesomeIcon icon={faPagelines} color={'#20546c'} size={"2x"}/>
            </div>
            <div>
              <p className='offer-caption'>Zajęcia przyrodnicze - doświadczenia i eksperymenty</p>
            </div>
          </div>
      </div>

      <div className='items-container-row'>
        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faCalculator} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Zajęcia matematyczne</p>
          </div>
        </div>

        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faHouse} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Nowoczesny, przestronny budynek i plac zabaw</p>
          </div>
        </div>

        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faUtensils} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Warsztaty kulinarne</p>
          </div>
        </div>

        <div className='offer-item'>
          <div className='offer-icon'>
            <FontAwesomeIcon icon={faComments} color={'#20546c'} size={"2x"}/>
          </div>
          <div>
            <p className='offer-caption'>Wsparcie logopedy</p>
          </div>
        </div>
      </div>

      <div className='additional-items-caption'>
        * Szczegółowa oferta zajęć dodatkowo płatnych zostanie przedstawiona przed rozpoczęciem roku przedszkolnego po dostosowaniu jej do zainteresowań Państwa dzieci.
      </div>
    </section>
  </div>
}
