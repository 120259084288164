import './Recruitment.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {TitleCaption} from "../TitleCaption/TitleCaption";

export const Recruitment = () => {
  return <div className='recrutiment-wrapper'>
    <section className='title'>
      <TitleCaption caption='Zapisy na rok przedszkolny 2025/2026'/>
    </section>

    <section className='download-document-wrapper'>
      <div className='download-document-title'>
        <p className='text'>Dokumenty do pobrania</p>
      </div>

      <a className='download-items-wrapper'
         href='/static/media/Karta-zapisu-Bajkowy-Globus.pdf'
         download
      >
        <div className={'item'}>
          <div className={'icon'}>
            <FontAwesomeIcon icon={faFilePdf} color={'#20546c'} size={"2x"}/>
          </div>
          <p className='contact-title'>Karta zapisu do przedszkola</p>
        </div>
      </a>
    </section>

    <section className='recruitment-info-wrapper'>
      <p className='title'>
        Szczegóły rekrutacji
      </p>
      <p className='text'>Rekrutacja odbędzie się na podstawie kolejności zgłoszeń.
        Rodzice zainteresowani zapisaniem Dziecka do przedszkola proszeni będą o wypełnienie dokumentu rekrutacyjnego
        (Karta zapisu do przedszkola)  i przesłanie go na nasz adres email: bajkowyglobus@gmail.com
      </p>
    </section>

  </div>

}
