import './Widget.scss'

export const Widget = ({offerName, price, entryFee, priceCustomInfo = 'miesięcznie'}) => {
  return <div className='widget-wrapper'>
    <div className='title'>
      {offerName}
    </div>
    <div className='divider' />
    <div>
      <p className='price'>{price} zł <span className='price-small'>{priceCustomInfo}</span></p>
    </div>

    <div className='widget-details'>
      <p className='caption-detail'>Wpisowe {entryFee} zł</p>
      <div className='detail-divider'/>
      <p className='caption-detail'>Cennik obowiązuje od września 2025&nbsp;r.</p>
      <div className='detail-divider'/>
      <p className='caption-detail'>+ Wyżywienie (dodatkowo płatne)</p>
    </div>
  </div>
}
